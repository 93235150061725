import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainPage from './components/Pages/MainPage';
import FourBusines from './components/Pages/FourBusines';
import FourCitizens from './components/Pages/FourCitizens';
import Contacts from './components/Pages/Contacts';

function App() {
  return (
    <Routes>
      <Route path='/' element = { <MainPage/> } />
      <Route path='/FourBusines' element = { <FourBusines/> } />
      <Route path='/FourCitizens' element = { <FourCitizens/> } />
      <Route path='/Contacts' element = { <Contacts/> } />
    </Routes>
  );
}

export default App;