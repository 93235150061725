import CustomizedAccordions from "../ItemFourBusines";
import '../styles/allStyles.scss'
import NewWhatsApp from "../NewWhatssApp";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

function FourBusines() {
    
    const UseNavigation = useNavigate();
    const handleclick = ()=> {
        UseNavigation(-1)
    }

    return (
    <>
        <main className="fourBusines">
            <div>
                <h1 className="fourBusines__header">ЮРИСТ ДЛЯ БИЗНЕСА:</h1>
                <button className="fourBusines__buttonBack" onClick={handleclick}><ArrowBackIcon sx={{fontSize: 30}}/><p>Назад</p></button>
                <button className="fourBusines__buttonBackM" onClick={handleclick}><ArrowBackIcon sx={{fontSize: 30}}/><p></p></button>
                <a className='fourBusines__phonenumber' href="tel:+79283338384">+7(928)-333-83-84</a>
                <span className="fourBusines__WhatsApp2"><NewWhatsApp/></span>
                <a href="mailto:ig.golovko@yandex.ru"><button className="fourBusines__sendtomail"></button></a>
                <section className="fourBusines__items">
                    <CustomizedAccordions />
                </section> 
            </div>

            <div>
                <section className="fourBusines-boxitem">
                    <h2 className="fourBusines-boxitem__advantages">ПРЕИМУЩЕСТВА РАБОТЫ С НАМИ:</h2>
                    <div className="fourBusines-boxitem-mainblock">
                        <div className="fourBusines-boxitem__advantages_mod">ИНДИВИДУАЛЬНЫЙ ПОДХОД<br /><span>Мы всегда учитываем пожелания и специфику клиента.</span></div>
                        <div className="fourBusines-boxitem__advantages_mod">ДОГОВОРНАЯ СТОИМОСТЬ УСЛУГ<br /><span>Индивидуальная договорная оценка каждой услуги. Без переплат.</span></div>
                        <div className="fourBusines-boxitem__advantages_mod">СПЕЦИАЛЬНЫЙ НАЛОГОВЫЙ РЕЖИМ<br /><span>Мы самостоятельно платим за себя налоги, как самозанятые.</span></div>
                        <div className="fourBusines-boxitem__advantages_mod">ГАРАНТИЯ КАЧЕСТВА УСЛУГ<br /><span>Изучаем каждый вопрос скурпулезно. Не вводим клиента в заблуждение. Ищем пути решения проблемы.</span></div>
                        <div className="fourBusines-boxitem__advantages_mod">ОТСУТСТВИЕ ПОБОЧНЫХ ЗАТРАТ<br /><span>Вам не нужно тратиться на рабочее место юриста и его обеспечение, у нас имеется все необходимое.</span></div>
                        <div className="fourBusines-boxitem__advantages_mod">МЫ РАБОТАЕМ 24/7<br /><span>Работаем в любое удобное для Вас время. Без выходных. Срочно решаем "горящие" вопросы.</span></div> 
                    </div>
                    <div>
                        <span className="fourBusines-boxitem__WhatsApp2"><NewWhatsApp/></span>
                        <a href="mailto:ig.golovko@yandex.ru"><button className="fourBusines-boxitem__sendtomail"></button></a>
                    </div>
                </section>
            </div>
            
        </main>
    </>
    )
}

export default FourBusines;