import React, { Component } from 'react';
import { format } from 'date-fns';
import './styles/allStyles.scss';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

class Feedback extends Component {

  state = {
    comments: [],
    form: {
      name: '',
      comment: ''
    }
  }

  componentDidMount() {
    if (localStorage.getItem('state')) {
      this.setState({ ...JSON.parse(localStorage.getItem('state')) })
    }
  }

  addComment = () => {
    this.setState({
      comments: [
        ...this.state.comments,
        {
          id: this.state.comments.length ? this.state.comments.reduce((p, c) => p.id > c.id ? p : c).id + 1 : 1, // max id +1
          name: this.state.form.name,
          comment: this.state.form.comment,
          date: new Date()
        }
      ],
      form: {
        name: '',
        comment: ''
      }
    }, () => localStorage.setItem('state', JSON.stringify(this.state)))
  }

  removeComment = (id) => {
    this.setState({
      comments: this.state.comments.filter(comment => comment.id !== id)
    }, () => localStorage.setItem('state', JSON.stringify(this.state)))
  }

  handleChange = (e) => {
    console.log(e.target.name)
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      }
    })
  }

  render() {
    return (
      <div className="feedbackbox-feedback">
        {this.state.comments.map(comment => <div key={comment.id}>
          <div style={{ fontStyle: 'italic' }}>{comment.id} - {format(comment.date, 'dd/mm/yyyy')}: </div>
          <strong>{comment.name}, </strong>
          <span>{comment.comment}</span>
          <button onClick={this.removeComment.bind(null, comment.id)}>Удалить отзыв</button>
        </div>)}

        <div className='feedbackbox-feedback__inputs'>
          <input 
            type="text"
            className='feedbackbox-feedback__inputs_input'
            placeholder='Ваше имя'
            value={this.state.form.name}
            name="name"
            onChange={this.handleChange}
          />

          <textarea
            name="comment"
            className='feedbackbox-feedback__inputs_mesage'
            placeholder='Оставьте Ваш отзыв о нас'
            value={this.state.form.comment}
            onChange={this.handleChange}>
          </textarea>

          <Stack direction="row" spacing={2} marginTop={3}>
            <Button
                disabled={this.state.form.comment.length === 0 || this.state.form.name.length === 0}
                variant="contained"
                type="submit" 
                value="Send"
                onClick={this.addComment} 
                sx={{ 
                height: 45,
                borderRadius: '10px',
                backgroundColor: '#FF8000',
                color: 'black',
                '&:hover': { 
                    backgroundColor: '#2EE59D',
                    color: 'white',
                    boxShadow: '0 15px 20px rgb(46, 229, 157, 0.4)',
                }}}
                endIcon={<SendIcon />}>ДОБАВИТЬ ОТЗЫВ
            </Button>
          </Stack>
        </div>
      </div>
    )
  }
}

export default Feedback
