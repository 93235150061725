import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function ImageAvatars() {
  return (
    <Stack direction="row" spacing={1}>
      <Avatar alt="Игорь Головко" src="/avatar/2.jpg" sx={{ width: 74, height: 74 }}/>
    </Stack>
  );
}