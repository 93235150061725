import React from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '../styles/allStyles.scss';
import NewWhatsApp from '../NewWhatssApp';
import ImageAvatars from '../Avatar';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { useRef } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import emailjs from '@emailjs/browser';

function MainPage() {

  const form = useRef(null)

  let [emailresolt, setEmailresolt] = useState('');
  let [nameresolt, setNameresolt] = useState('');

  /*let emailresolt = ''
  let mailInput = (e)=>{
    emailresolt = e.target.value
  }*/

  let mail = useRef(null)
  let emptyMail = useRef(null)
  let errorMail = useRef(null)

  let checkboxResolt = ''
  let checkInput = (e)=>{
    checkboxResolt = e.target.checked     
  }
  let emptyCheck = useRef(null)

  const [phone, setPhone] = React.useState('')

  const handleChange = (newPhone) => {
    setPhone(newPhone)
  }
  
  let sendEmail = async(e)=>{
    e.preventDefault()

  function validateEmail(emailresolt) {const re = /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
  return re.test(String(emailresolt).toLowerCase());
  }
  console.log(emailresolt);
  console.log(nameresolt);
  console.log(checkboxResolt);
  console.log(mail);
  console.log(emptyCheck);

  let checkmail = validateEmail(emailresolt)

  if (emailresolt.length === 0 && checkboxResolt === false) {
    emptyMail.current.style.display = 'block';
    emptyCheck.current.style.display = 'block';
    mail.current.style.borderColor = 'red';
  }
  else if (emailresolt.length === 0) {
    emptyMail.current.style.display = 'block';
  }
  else if (checkboxResolt === false) {
    emptyCheck.current.style.display = 'block';
  }
  else if (checkmail===false) {
    errorMail.current.style.display = 'block';
  }
  else {
    emptyMail.current.style.display = 'none';
    emptyCheck.current.style.display = 'none';
    errorMail.current.style.display = 'none';
    mail.current.style.borderColor = 'green';

    emailjs.sendForm('Pochta', 'template_c1jnzos', form.current, 'F3LI3Ow9-m1kA8saY')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });    
    alert('Спасибо за Вашу заявку! Скоро мы с Вами свяжемся.');
  }
}
  return (
    <div className = "App">
      <div id='top3' className = 'wrapper'>
        <header className = "header">
            <h1 className = "header__title">Юриcт онлайн</h1>
            <a className='header__phonenumber' href="tel:+79283338384">+7(928)-333-83-84</a>
            <span className='header__whatsApp'><NewWhatsApp/></span>
            <div className = "header__buttons">
              <Link to='FourBusines'><button className = "header__buttons_size">Юрист для бизнеса</button></Link>
              <Link to='FourCitizens'><button className = "header__buttons_size">Юрист для граждан</button></Link>
              <a href='#top1'><button className = "header__buttons_size">Консультация</button></a>
              <Link to='Contacts'><button className = "header__buttons_size">Контакты</button></Link>
            </div>
        </header>

        <motion.section
          initial={{
            x: 3000,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            delay: 1,
            duration: 2,
          }}
          className = "advantages">

          <div className = "advantages__box">
              <h2>БЕЗУПРЕЧНАЯ РЕПУТАЦИЯ</h2><p>Большинство вопросов поступают к нам по рекомендациям от наших клиентов, мы очень дорожим своим именем и наша команда работает только на результат.</p>
          </div>
          <div className = "advantages__box">
              <h2>БОЛЬШОЙ ОПЫТ РАБОТЫ</h2><p>Только опытные и профессиональные юристы, со стажем 15 и более лет, что позволяет нам добиваться наилучших результатов в делах любой сложности.</p>
          </div>
          <div className = "advantages__box">
              <h2>УДОБНЫЙ ГРАФИК РАБОТЫ</h2><p>Бесплатная консультация юриста онлайн по телефону круглосуточно, без регистрации. Наши юристы работают без выходных. Берем срочные заказы.</p>
          </div>
          <div className = "advantages__box">
              <h2>РАБОТАЕМ «ПОД КЛЮЧ»</h2><p>Наши клиенты полностью избавлены от посещения судебных заседаний, принимают минимальное участие в решении проблемы.</p>
          </div>
          <div className = "advantages__box">
              <h2>РАБОТАЕМ ПО ВСЕМУ МИРУ</h2><p>Используем в работе самые современные технологии, что позволяет эффективно вести дела по всему миру.</p>
          </div>
        </motion.section>

        <h2 className = "title">Чем могут помочь наши юристы?</h2>

        <section className = "help">
            <div className = "help__item">
              
              <div className='help__item_box'>
                  <div>
                      <span>Оформление прав:</span>
                      <ul>
                        <li>Консультирование</li>
                        <li>Составление договоров</li>
                        <li>Проверка документации</li>
                        <li>Оформления передачи прав</li>
                        <li>Расторжение договоров</li>
                      </ul>
                  </div>
                  <div className='help__item_img1'></div>
              </div>
            </div>

            <div className = "help__item">
              <div className='help__item_box'>
                <div>
                  <span>Судебная защита:</span>
                  <ul>
                    <li>Предъявления искового заявления в суд</li>
                    <li>Участие в судебных заседаниях</li>
                    <li>Подготовка процессуальных документов</li>
                    <li>Участие в сборе доказательств</li>
                    <li>Обжалование судебных решений</li>
                  </ul>  
                </div>
                <div className='help__item_img2'></div>              
              </div>
            </div>

            <div className = "help__item">
              <div className='help__item_box'>
                <div>
                  <span>Защита ваших прав:</span>
                  <ul>
                      <li>Претензионное урегулирование споров</li>
                      <li>Досудебные переговоры</li>
                      <li>Предъявления жалоб в гос.органы</li>
                      <li>Исполнение решений судов</li>
                      <li>Взыскание денежных средств</li>
                  </ul>
                </div>
                <div className='help__item_img3'></div>              
              </div>
            </div>

            <div className = "help__item">
              <div className='help__item_box'>
                <div>
                  <span>Юридическая консультация онлайн:</span>
                  <ul>
                    <li>юрист по разводам, алиментам, разделу имущества </li>
                    <li>юрист по недвижимости, земельным вопросам</li>
                    <li>юрист по уголовным делам</li>
                    <li>юрист по наследственным делам</li>
                    <li>юрист по гражданским делам</li>
                    <li>юрист по жилищным вопросам</li>
                    <li>юрист по ДТП</li>
                    <li>юрист по трудовым спорам</li>
                    <li>финансовый, банковский юрист</li>
                    <li>консультация военного юриста бесплатно</li>
                  </ul>  
                </div>
                <div className='help__item_img4'></div>              
              </div>
            </div>

            <div className='adaptationHelp'>
              <div className = "help__itemshot">
              <div className='help__itemshot_box'>
                  <div>
                      <span>Правовая экспертиза:</span>
                      <ul>
                        <li>Юридическая оценка условий договоров</li>
                        <li>Правовая экспаертиза документов</li>
                        <li>Квалифицированная правовая оценка ситуации <br /> и предложение путей решения</li>
                      </ul>
                  </div>
                  <div className='help__itemshot_img5'></div>
              </div>
            </div>

            <div className = "help__itemshot">
              <div className='help__itemshot_box'>
                  <div>
                      <span>Регистрация бизнеса:</span>
                      <ul>
                        <li>Создание юридических лиц</li>
                        <li>Внесение изменений <br /> в учредительные документы</li>
                        <li>Регистрация ИП</li>
                      </ul>
                  </div>
                  <div className='help__itemshot_img6'></div>
                </div>
              </div>
            </div>
        </section>

        <section className = "helpM">
            <div className = "helpM__item">
              
              <div className='helpM__item_box'>
                  <div>
                      <span>Оформление прав:</span>
                      <ul>
                        <li>Консультирование</li>
                        <li>Составление договоров</li>
                        <li>Проверка документации</li>
                        <li>Оформления передачи прав</li>
                        <li>Расторжение договоров</li>
                      </ul>
                  </div>
                  <div className='helpM__item_img1'></div>
              </div>
            </div>

            <div className = "helpM__item">
              <div className='helpM__item_box'>
                <div>
                  <span>Судебная защита:</span>
                  <ul>
                    <li>Предъявления искового заявления в суд</li>
                    <li>Участие в судебных заседаниях</li>
                    <li>Подготовка процессуальных документов</li>
                    <li>Участие в сборе доказательств</li>
                    <li>Обжалование судебных решений</li>
                  </ul>  
                </div>
                <div className='helpM__item_img2'></div>              
              </div>
            </div>

            <div className = "helpM__item">
              <div className='helpM__item_box'>
                <div>
                  <span>Защита ваших прав:</span>
                  <ul>
                      <li>Претензионное урегулирование споров</li>
                      <li>Досудебные переговоры</li>
                      <li>Предъявления жалоб в гос.органы</li>
                      <li>Исполнение решений судов</li>
                      <li>Взыскание денежных средств</li>
                  </ul>
                </div>
                <div className='helpM__item_img3'></div>              
              </div>
            </div>

            <div className = "helpM__item">
              <div className='helpM__item_box'>
                <div>
                    <span>Правовая экспертиза:</span>
                    <ul>
                      <li>Юридическая оценка условий договоров</li>
                      <li>Правовая экспаертиза документов</li>
                      <li>Квалифицированная правовая оценка ситуации <br /> и предложение путей решения</li>
                    </ul>
                </div>
                <div className='helpM__item_img5'></div>
              </div>
            </div>

            <div className = "helpM__item">
              <div className='helpM__item_box'>
                <div>
                  <span>Юридическая консультация онлайн:</span>
                  <ul>
                    <li>юрист по разводам, алиментам, разделу имущества </li>
                    <li>юрист по недвижимости, земельным вопросам</li>
                    <li>юрист по уголовным делам</li>
                    <li>юрист по наследственным делам</li>
                    <li>юрист по гражданским делам</li>
                    <li>юрист по жилищным вопросам</li>
                    <li>юрист по ДТП</li>
                    <li>юрист по трудовым спорам</li>
                    <li>финансовый, банковский юрист</li>
                    <li>консультация военного юриста бесплатно</li>
                  </ul>  
                </div>
                <div className='helpM__item_img4'></div>              
              </div>
            </div>

            <div className = "helpM__item">
              <div className='helpM__item_box'>
                <div>
                  <span>Регистрация бизнеса:</span>
                    <ul>
                      <li>Создание юридических лиц</li>
                      <li>Внесение изменений <br /> в учредительные документы</li>
                      <li>Регистрация ИП</li>
                    </ul>
                  </div>
                <div className='helpM__item_img6'></div>
              </div>
            </div>

          </section>


        <form ref={form} onSubmit={sendEmail} className = "form">
          <h3 id='top1' className='form-header'>Заявка на беспланую консультацию:</h3>
          <p className='form-header__info'>После отправки формы, профессиональный юрист, свяжется с Вами в самое ближайшее время</p>

          <input 
            type="text" 
            name="her_name" 
            placeholder='Как к Вам можно обращаться?' 
            className='form__input'
            value = {nameresolt} 
            onChange = { e => setNameresolt(e.target.value)}
          />

          <input 
            type="email" 
            name="from_name" 
            placeholder='Ваш E-mail'
            ref = {mail} 
            className='form__input' 
            value = {emailresolt} 
            onChange = { e => setEmailresolt(e.target.value)}
          />
              <span ref = {emptyMail} className = 'form__error'>Поле не должно быть пустым.</span>
              <span ref = {errorMail} className = 'form__error'>Email невалидный</span>

          <div className='phoneCountry'>
            <MuiTelInput 
              sx={{width: '300px'}} 
              splitCallingCode={true} 
              defaultCountry="RU"
              value={phone} 
              onChange={handleChange} 
              name='number_phone'
            />   
          </div>

          <textarea name="message" placeholder='Опишите пожалуйста кратко Ваш вопрос' className='form__mesage'/>

          <div className='check24-20'>
            <FormGroup >
                <FormControlLabel onInput={checkInput} control={<Checkbox defaultChecked />} label="Я предоставляю свое согласие на обработку моих персональных данных" 
                sx={{ 
                marginTop: 2,
                '& .css-ahj2mt-MuiTypography-root': {fontSize: 15},
                '& .MuiSvgIcon-root': { fontSize: 30 }
                }}/>
            </FormGroup>
          </div>

          <div className='check19-10'>
            <FormGroup >
                <FormControlLabel onInput={checkInput} control={<Checkbox defaultChecked />} label="Я предоставляю свое согласие на обработку моих персональных данных" 
                sx={{ 
                marginTop: 2,
                '& .css-ahj2mt-MuiTypography-root': {fontSize: 13.7},
                '& .MuiSvgIcon-root': { fontSize: 25 }
                }}/>
            </FormGroup>
          </div>
          <span ref = {emptyCheck} className = 'form__error'>Поле не должно быть пустым.</span>

          <div className='form__sendButton'>
            <Stack direction="row" spacing={2}>     
              <Button
                disabled={emailresolt.length === 0} 
                variant="contained"
                type="submit" 
                value="Send" 
                  sx={{ 
                  height: 45,
                  borderRadius: '10px',
                  backgroundColor: '#FF8000',
                  color: 'black',
                  '&:hover': { 
                      backgroundColor: '#2EE59D',
                      color: 'white',
                      boxShadow: '0 15px 20px rgb(46, 229, 157, 0.4)',
                  }}}
                endIcon={<SendIcon />}>Заказать консультацию
              </Button>
            </Stack>
          </div>

        </form>
          
        <footer className='footer'>
          <a href='#top3' className='top3'>
            <button className='footer__buttonAp'>
              В начало<ArrowCircleUpIcon sx={{fontSize:40, marginLeft:1.6}}/>
            </button>
            <button className='footer__buttonAp15'>
              В начало<ArrowCircleUpIcon sx={{fontSize:30, marginLeft:1}}/>
            </button>
            <button className='footer__buttonAp13'>
              В начало<ArrowCircleUpIcon sx={{fontSize:20, marginLeft:0.5}}/>
            </button>
          </a>
          
          <div className='footer__ava'>
            <p>Услуги оказывает квалифицированный Юрист: Головко Игорь Александрович</p>
            <ImageAvatars/>
          </div>

          <div>
            <p>Контакты:</p>
            <div className='footer-box'>
              <span>Позвонить юристу (Телефон):</span>
              <a className='footer-box__contacts' href="tel:+79283338384"> 
                <CallIcon sx={{height: 20, position: 'absolute', buttom: 0, marginLeft: 1}} />
                <span className='footer-box__contacts_number'>+7(928)-333-83-84</span>
              </a>
            </div>

            <div className='footer-box'>
              <span>Написать нам письмо (e-mail):</span>
              <a className='footer-box__contacts' href="mailto:ig.golovko@yandex.ru">
                <EmailIcon sx={{height: 30, position: 'absolute', buttom: 0, marginLeft: 0.5}}/>
                <span className='footer-box__contacts_number'>ig.golovko@yandex.ru</span>
              </a>
            </div>
          </div>
          
          <Link to='FourCitizens' className='footer__links'><div>Юрист для граждан</div></Link>
          <Link to='FourBusines' className='footer__links'><div>Юрист для бизнеса</div></Link>
        </footer>
        <div className='lastButton'>
          <a href='#top3' className='top3'>
            <button className='footer__buttonAp12'>
              В начало<ArrowCircleUpIcon sx={{fontSize:20, marginLeft:0.5}}/>
            </button>
          </a>          
        </div>
      </div>

      <div id='top3(M)' className='wrapperM'>

        <header className = "headerM">
            <h1 className = "headerM__title">Юрист онлайн</h1>
            <a className='headerM__phonenumber' href="tel:+79283338384">+7(928)-333-83-84</a>
            <span className='headerM__whatsApp'><NewWhatsApp/></span>
            <div className = "headerM__buttons">
              <Link to='FourBusines'><button className = "headerM__buttons_size">Юрист для бизнеса</button></Link>
              <Link to='FourCitizens'><button className = "headerM__buttons_size">Юрист для граждан</button></Link>
              <a href='#top1(M)'><button className = "headerM__buttons_size">Консультация</button></a>
              <a href="#top2(M)"><button className = "headerM__buttons_size">Контакты</button></a>
            </div>
        </header>
        
        <motion.section
          initial={{
            x: 3000,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            delay: 0.2,
            duration: 2,
          }}
          className = "advantagesM">
          <div className = "advantagesM__box">
              <h2>БЕЗУПРЕЧНАЯ РЕПУТАЦИЯ</h2><p>Большинство вопросов поступают к нам по рекомендациям от наших клиентов, мы очень дорожим своим именем и наша команда работает только на результат.</p>
          </div>
        </motion.section>

        <motion.section
          initial={{
            x: 3000,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            delay: 0.4,
            duration: 2,
          }}
          className = "advantagesM">
            <div className = "advantagesM__box">
                <h2>БОЛЬШОЙ ОПЫТ РАБОТЫ</h2><p>Только опытные и профессиональные юристы, со стажем 15 и более лет, что позволяет нам добиваться наилучших результатов в делах любой сложности.</p>
            </div>
          </motion.section>

          <motion.section
          initial={{
            x: 3000,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            delay: 0.6,
            duration: 2,
          }}
          className = "advantagesM">
            <div className = "advantagesM__box">
                <h2>УДОБНЫЙ ГРАФИК РАБОТЫ</h2><p>Бесплатная консультация юриста онлайн по телефону круглосуточно, без регистрации. Наши юристы работают без выходных. Берем срочные заказы.</p>
            </div>
          </motion.section>

          <motion.section
          initial={{
            x: 3000,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            delay: 0.8,
            duration: 2,
          }}
          className = "advantagesM">
            <div className = "advantagesM__box">
                <h2>РАБОТАЕМ «ПОД КЛЮЧ»</h2><p>Наши клиенты полностью избавлены от посещения судебных заседаний, принимают минимальное участие в решении проблемы.</p>
            </div>
          </motion.section>

          <motion.section
          initial={{
            x: 3000,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            delay: 1,
            duration: 2,
          }}
          className = "advantagesM">
            <div className = "advantagesM__box">
                <h2>РАБОТАЕМ ПО ВСЕМУ МИРУ</h2><p>Используем в работе самые современные технологии, что позволяет эффективно вести дела по всему миру.</p>
            </div>
          </motion.section>

          <h2 className = "title">Чем могут помочь наши юристы?</h2>

          <section className = "help">
              <div className = "help__item">
                <div className='help__item_box'>
                    <div>
                        <span>Оформление прав:</span>
                        <ul>
                          <li>Консультирование</li>
                          <li>Составление договоров</li>
                          <li>Проверка документации</li>
                          <li>Оформления передачи прав</li>
                          <li>Расторжение договоров</li>
                        </ul>
                    </div>
                    <div className='help__item_img1'></div>
                </div>
              </div>

              <div className = "help__item">
                <div className='help__item_box'>
                  <div>
                    <span>Судебная защита:</span>
                    <ul>
                      <li>Предъявления искового заявления в суд</li>
                      <li>Участие в судебных заседаниях</li>
                      <li>Подготовка процессуальных документов</li>
                      <li>Участие в сборе доказательств</li>
                      <li>Обжалование судебных решений</li>
                    </ul>  
                  </div>
                  <div className='help__item_img2'></div>              
                </div>
              </div>

              <div className = "help__item">
                <div className='help__item_box'>
                  <div>
                    <span>Защита ваших прав:</span>
                    <ul>
                        <li>Претензионное урегулирование споров</li>
                        <li>Досудебные переговоры</li>
                        <li>Предъявления жалоб в гос.органы</li>
                        <li>Исполнение решений судов</li>
                        <li>Взыскание денежных средств</li>
                    </ul>
                  </div>
                  <div className='help__item_img3'></div>              
                </div>
              </div>

              <div className = "help__item">
                <div className='help__item_box'>
                  <div>
                    <span>Юридическая консультация онлайн:</span>
                    <ul>
                      <li>юрист по разводам, алиментам, разделу имущества </li>
                      <li>юрист по недвижимости, земельным вопросам</li>
                      <li>юрист по уголовным делам</li>
                      <li>юрист по наследственным делам</li>
                      <li>юрист по гражданским делам</li>
                      <li>юрист по жилищным вопросам</li>
                      <li>юрист по ДТП</li>
                      <li>юрист по трудовым спорам</li>
                      <li>финансовый, банковский юрист</li>
                      <li>консультация военного юриста бесплатно</li>
                    </ul>  
                  </div>
                  <div className='help__item_img4'></div>              
                </div>
              </div>

              <div className = "help__item">
                <div className='help__item_box'>
                    <div>
                        <span>Правовая экспертиза:</span>
                        <ul>
                          <li>Юридическая оценка условий договоров</li>
                          <li>Правовая экспаертиза документов</li>
                          <li>Квалифицированная правовая оценка ситуации <br /> и предложение путей решения</li>
                        </ul>
                    </div>
                    <div className='help__item_img5'></div>
                </div>
              </div>

              <div className = "help__item">
                <div className='help__item_box'>
                    <div>
                        <span>Регистрация бизнеса:</span>
                        <ul>
                          <li>Создание юридических лиц</li>
                          <li>Внесение изменений <br /> в учредительные документы</li>
                          <li>Регистрация ИП</li>
                        </ul>
                    </div>
                  <div className='help__item_img6'></div>
                </div>
              </div>

          </section>

          <form ref={form} onSubmit={sendEmail} className = "form">
            <h3 id='top1(M)' className='form-header'>Заявка на беспланую консультацию:</h3>
            <p className='form-header__info'>После отправки формы, профессиональный юрист, свяжется с Вами в самое ближайшее время</p>

            <input 
              type="text" 
              name="her_name" 
              placeholder='Как к Вам можно обращаться?' 
              className='form__input'
              value = {nameresolt}
              onChange = { e => setNameresolt(e.target.value)}
            />

            <input 
              type="email"
              name="from_name"
              placeholder='Ваш E-mail'
              ref = {mail}
              className='form__input'
              value = {emailresolt}
              onChange = { e => setEmailresolt(e.target.value)}
            />
                <span ref = {emptyMail} className = 'form__error'>Поле не должно быть пустым.</span>
                <span ref = {errorMail} className = 'form__error'>Email невалидный</span>

            <div className='phoneCountry'>
              <MuiTelInput 
                sx={{width:'270px'}}
                splitCallingCode={true} 
                defaultCountry="RU"
                value={phone} 
                onChange={handleChange} 
                name='number_phone'
              />   
            </div>

            <textarea name="message" placeholder='Опишите пожалуйста кратко Ваш вопрос' className='form__mesage'/>

            <div className='check19-10'>
              <FormGroup >
                  <FormControlLabel onInput={checkInput} control={<Checkbox defaultChecked />} label="Я предоставляю свое согласие на обработку моих персональных данных" 
                  sx={{ 
                    marginTop: 2,
                    '& .css-ahj2mt-MuiTypography-root': {fontSize: 10},
                    '& .MuiSvgIcon-root': { fontSize: 25 }
                  }}/>
              </FormGroup>
            </div>
            <span ref = {emptyCheck} className = 'form__error'>Поле не должно быть пустым.</span>

            <div className='form__sendButton'>
              <Stack direction="row" spacing={2}>     
                <Button
                  disabled={emailresolt.length === 0} 
                  variant="contained"
                  type="submit" 
                  value="Send" 
                    sx={{ 
                    height: 45,
                    borderRadius: '10px',
                    backgroundColor: '#FF8000',
                    fontSize: '80%',
                    color: 'black',
                    '&:hover': { 
                        backgroundColor: '#2EE59D',
                        color: 'white',
                        boxShadow: '0 15px 20px rgb(46, 229, 157, 0.4)',
                    }}}
                  endIcon={<SendIcon />}>Заказать консультацию
                </Button>
              </Stack>
            </div>

          </form>
  
          <footer className='footerM'>
            
            <div>Услуги оказывает квалифицированный Юрист: Головко Игорь Александрович</div>

            <div className='footerM-box'>

              <div className='footerM-box-towBlock'><ImageAvatars/></div>    

              <div className='footerM-box-towBlock'>
                <span id='top2(M)'>Позвонить юристу (Телефон):</span><br />
                <a className='footerM-box-towBlock__contacts' href="tel:+79283338384"> 
                  <CallIcon sx={{height: 20, position: 'absolute', buttom: 0, marginLeft: -2.5}} />
                  <span className='footerM-box-towBlock__contacts_number'>+7(928)-333-83-84</span>
                </a><br />

                <span>Написать нам письмо (e-mail):</span><br />
                <a className='footerM-box-towBlock__contacts' href="mailto:ig.golovko@yandex.ru">
                    <EmailIcon sx={{height: 30, position: 'absolute', buttom: 0, marginLeft: -3.5}}/>
                    <span className='footerM-box-towBlock__contacts_number'>ig.golovko@yandex.ru</span>
                </a>
              </div>
            </div>

            <div className='footerM-links'>
              <Link to='FourCitizens' className = 'footerM-links__link'><div>Юрист для граждан</div></Link>
              <Link to='FourBusines' className = 'footerM-links__link'><div>Юрист для бизнеса</div></Link>    
            </div>

          </footer>

          <div className='lastButton'>
            <a href='#top3(M)' className='top3'>
              <button className='lastButton__buttonAp'>
                В начало<ArrowCircleUpIcon sx={{fontSize:20, marginLeft:0.5}}/>
              </button>
            </a>          
          </div>
      </div>
    </div>
  );
}

export default MainPage;