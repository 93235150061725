import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.1),
  borderTop: '3px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
<>
  <div className='min20'>
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular',}} >Договорная работа:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{
          fontSize: 18,
          color: '#0000FF',
          fontWeight: 400,
          fontFamily: 'Noto Serif Regular',
        }} >
          <li>Проверка контрагентов компании (выявление неблагонадежных, проявление должной осмотрительности); </li>
          <li>Разработка договоров, соглашений под каждое направление деятельности компании;</li>
          <li>Правовой анализ условий договора. Проверка соответствия договоров действующей нормативно-правовой базе. Поиск заранее невыгодных условий для компании. Составление протокола разногласий;</li>
          <li>Выяснение позиции контрагента, ведение переговоров с контрагентом по урегулированию разноглассий;</li>
          <li>Разработка и заключение международных контрактов на английском, немецком, французском, китайском и др. языках;</li>
          <li>Составление дополнительных соглашений;</li>
          <li>Поиск дополнительных условий, необходимых для реализации сделки;</li>
          <li>Нивелирование возможных рисков и негативных последствий ненадлежащего исполнения договора;</li>
          <li>Прекращение договора через третейский или арбитражный суд.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <Typography sx={{ fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Корпоративное право:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{fontSize: 17, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
          <li>Создание юридического лица любой организационно-правовой формы (ООО, АО и др.);</li>
          <li>Разработка и оформление учредительных документов (устав, учредительный договор, протоколы общего собрания, решения и др.)</li>
          <li>Регистрация юридического лица в ИФНС;</li>
          <li>Ликвидация юридического лица;</li>
          <li>Сопровождение изменений форм собственности, видов деятельности, внесения изменений в учредительные документы;</li>
          <li>Изменение размера и структуры уставного капитала;</li>
          <li>Изменение структуры и компетенции органов управления;</li>
          <li>Ведение и протоколирование собраний совета директоров и других полномочных органов управления компанией;</li>
          <li>Правовой аудит корпоративной документации;</li>
          <li>Представительство компании у нотариуса;</li>
          <li>Разработка стратегии и сопровождение сделок купли-продажи компаний, слияния, поглощения и реорганизации;</li>
          <li>Разработка и составление локальных нормативных актов (приказы, положения, распоряжения, регламенты) и их анализ на соответствие действующей нормативно-праовой базе;</li>
          <li>Консультирование руководства и работников компании по юридическим аспектам;</li>
          <li>Проверка законности увольнений, штрафов для работников и т.д.;</li>
          <li>Контроль изменений в законодательстве;</li>
          <li>Ведение деловой переписки.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <Typography sx={{ fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Претензионно-исковая работа, защита в суде:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
          <li>Проведение деловых переговоров, переписки и встреч, в целях урегулирования спора в досудебном порядке;</li>
          <li>Сбор доказательной базы;</li>
          <li>Оценка рисков до обращения в суд, составление мотивированного письменного заключения;</li>
          <li>Составление и направление обоснованной претензии (соблюдение предусмотренного законом порядка досудебного урегулирования спора);</li>
          <li>Урегулирование споров в претензионном порядке (заключение мировых соглашений, протоколирование переговоров);</li>
          <li>Подготовка и обоснование исковых требований;</li>
          <li>Составление искового заявления, заявления о выдаче судебного приказа, обращение в суд; </li>
          <li>Защита интересов компании в судебных разбирательствах: участие в судебных заседаниях, подготовка процессуальных документов (мотивированных отзывов, возражений).</li>
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
      <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
        <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Защита интересов компании в службе судебных приставов:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
          <li>Получение исполнительного документа в суде;</li>
          <li>Предъявление исполнительного документа в банк, службу судебных приставов;</li>
          <li>Контроль работы службы судебных приставов, подача процессуальных документов (жалоб, ходатайств);</li>
          <li>Участие в аресте, оценке и реализации имущества должника.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
      <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
        <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Получение  лицензии на алкоголь:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
          <li>Получение лицензии на продажу алкоголя в баре;</li>
          <li>Получение лицензии на продажу алкоголя в розницу, в магазине;</li>
          <li>Лицензия на алкоголь, как её получить, требования, стоимость, необходимые документы;</li>
          <li>Получение лицензии на производство, хранение и поставку алкогольной продукции;</li>
          <li>Письменное заключение о процедуре лицензирования. Оценка рисков;</li>
          <li>Подготовка необходимого пакета документов для получения лицензии;</li>
          <li>Сопровождение процесса лицензирования. Взаимодействие с исполнительным и надзорным органом;</li>
          <li>Правовая оценка рисков ведения лицензируемого вида деятельности.</li>
          <li>Переоформление действующих лицензий (подготовка документов, отправка в лицензирующий орган);</li>
          <li>Продление лицензии.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>

  </div>

  <div className='max19'>
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular',}} >Договорная работа:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{
          lineHeight: 1.1,
          fontSize: 16,
          color: '#0000FF',
          fontWeight: 400,
          fontFamily: 'Noto Serif Regular',
        }} >
          <li>Проверка контрагентов компании (выявление неблагонадежных, проявление должной осмотрительности); </li>
          <li>Разработка договоров, соглашений под каждое направление деятельности компании;</li>
          <li>Правовой анализ условий договора. Проверка соответствия договоров действующей нормативно-правовой базе. Поиск заранее невыгодных условий для компании. Составление протокола разногласий;</li>
          <li>Выяснение позиции контрагента, ведение переговоров с контрагентом по урегулированию разноглассий;</li>
          <li>Разработка и заключение международных контрактов на английском, немецком, французском, китайском и др. языках;</li>
          <li>Составление дополнительных соглашений;</li>
          <li>Поиск дополнительных условий, необходимых для реализации сделки;</li>
          <li>Нивелирование возможных рисков и негативных последствий ненадлежащего исполнения договора;</li>
          <li>Прекращение договора через третейский или арбитражный суд.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <Typography sx={{ fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Корпоративное право:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
          <li>Создание юридического лица любой организационно-правовой формы (ООО, АО и др.);</li>
          <li>Разработка и оформление учредительных документов (устав, учредительный договор, протоколы общего собрания, решения и др.)</li>
          <li>Регистрация юридического лица в ИФНС;</li>
          <li>Ликвидация юридического лица;</li>
          <li>Сопровождение изменений форм собственности, видов деятельности, внесения изменений в учредительные документы;</li>
          <li>Изменение размера и структуры уставного капитала;</li>
          <li>Изменение структуры и компетенции органов управления;</li>
          <li>Ведение и протоколирование собраний совета директоров и других полномочных органов управления компанией;</li>
          <li>Правовой аудит корпоративной документации;</li>
          <li>Представительство компании у нотариуса;</li>
          <li>Разработка стратегии и сопровождение сделок купли-продажи компаний, слияния, поглощения и реорганизации;</li>
          <li>Разработка и составление локальных нормативных актов (приказы, положения, распоряжения, регламенты) и их анализ на соответствие действующей нормативно-праовой базе;</li>
          <li>Консультирование руководства и работников компании по юридическим аспектам;</li>
          <li>Проверка законности увольнений, штрафов для работников и т.д.;</li>
          <li>Контроль изменений в законодательстве;</li>
          <li>Ведение деловой переписки.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <Typography sx={{ fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Претензионно-исковая работа, защита в суде:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
          <li>Проведение деловых переговоров, переписки и встреч, в целях урегулирования спора в досудебном порядке;</li>
          <li>Сбор доказательной базы;</li>
          <li>Оценка рисков до обращения в суд, составление мотивированного письменного заключения;</li>
          <li>Составление и направление обоснованной претензии (соблюдение предусмотренного законом порядка досудебного урегулирования спора);</li>
          <li>Урегулирование споров в претензионном порядке (заключение мировых соглашений, протоколирование переговоров);</li>
          <li>Подготовка и обоснование исковых требований;</li>
          <li>Составление искового заявления, заявления о выдаче судебного приказа, обращение в суд; </li>
          <li>Защита интересов компании в судебных разбирательствах: участие в судебных заседаниях, подготовка процессуальных документов (мотивированных отзывов, возражений).</li>
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
      <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
        <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Защита интересов компании в службе судебных приставов:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
          <li>Получение исполнительного документа в суде;</li>
          <li>Предъявление исполнительного документа в банк, службу судебных приставов;</li>
          <li>Контроль работы службы судебных приставов, подача процессуальных документов (жалоб, ходатайств);</li>
          <li>Участие в аресте, оценке и реализации имущества должника.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
      <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
        <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Лицензирование:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
          <li>Письменное заключение о процедуре лицензирования конкретного вида деятельности. Оценка рисков;</li>
          <li>Подготовка необходимого пакета документов для получения лицензии;</li>
          <li>Сопровождение процесса лицензирования. Взаимодействие с исполнительным и надзорным органом;</li>
          <li>Правовая оценка рисков ведения лицензируемого вида деятельности.</li>
          <li>Переоформление действующих лицензий (подготовка документов, отправка в лицензирующий орган);</li>
          <li>Продление лицензии.</li>
        </Typography>
      </AccordionDetails>
    </Accordion>

  </div>
</>
  );
}