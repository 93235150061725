import * as React from 'react';
import '../styles/allStyles.scss'
import NewWhatsApp from "../NewWhatssApp";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { useState } from 'react';
import { YMaps, Map } from '@pbe/react-yandex-maps';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MuiTelInput } from 'mui-tel-input';
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Feedback from '../Feedback';
import { Placemark } from '@pbe/react-yandex-maps';

function Contacts() {
    
    const UseNavigation = useNavigate();
    const handleclick = ()=> {
        UseNavigation(-1)
    }

    const form = useRef(null)

    let [emailresolt, setEmailresolt] = useState('');
    let [nameresolt, setNameresolt] = useState('');
  
    let mail = useRef(null)
    let emptyMail = useRef(null)
    let errorMail = useRef(null)
  
    let checkboxResolt = ''
    let checkInput = (e)=>{
      checkboxResolt = e.target.checked     
    }
    let emptyCheck = useRef(null)
  
    const [phone, setPhone] = React.useState('')
  
    const handleChange = (newPhone) => {
      setPhone(newPhone)
    }

    let sendEmail = async(e)=>{
      e.preventDefault()
  
    function validateEmail(emailresolt) {const re = /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
    return re.test(String(emailresolt).toLowerCase());
    }
    console.log(emailresolt);
    console.log(nameresolt);
    console.log(checkboxResolt);
    console.log(mail);
    console.log(emptyCheck);
  
    let checkmail = validateEmail(emailresolt)
  
    if (emailresolt.length === 0 && checkboxResolt === false) {
      emptyMail.current.style.display = 'block';
      emptyCheck.current.style.display = 'block';
      mail.current.style.borderColor = 'red';
    }
    else if (emailresolt.length === 0) {
      emptyMail.current.style.display = 'block';
    }
    else if (checkboxResolt === false) {
      emptyCheck.current.style.display = 'block';
    }
    else if (checkmail===false) {
      errorMail.current.style.display = 'block';
    }
    else {
      emptyMail.current.style.display = 'none';
      emptyCheck.current.style.display = 'none';
      errorMail.current.style.display = 'none';
      mail.current.style.borderColor = 'green';
  
      emailjs.sendForm('Pochta', 'template_c1jnzos', form.current, 'F3LI3Ow9-m1kA8saY')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });    
      alert('Спасибо за Вашу заявку! Скоро мы с Вами свяжемся.');
    }
}

    return (
    <>
        <div className='contactWrapper'>
            <header >
                <button className="fourBusines__buttonBack" onClick={handleclick}><ArrowBackIcon sx={{fontSize: 30}}/><p>Назад</p>
                </button>
                <button className="fourBusines__buttonBackM" onClick={handleclick}><ArrowBackIcon sx={{fontSize: 30}}/><p></p></button>
                <div className='ContactsHeader'>
                    <h1>ИНФОРМАЦИЯ ДЛЯ КЛИЕНТОВ:</h1>                
                </div>
            </header>

            <main className='ContactsMain'>
                <table className='ContactsMain-table'>
                    <tr>
                        <td className='ContactsMain-table__line'>РЕКВИЗИТЫ ЮРИСТА:</td>
                        <td className='ContactsMain-table__line'>Индивидуальный предприниматель Головко Игорь Александрович</td>
                    </tr>
                    <tr>
                        <td className='ContactsMain-table__line'>ИНН:</td>
                        <td className='ContactsMain-table__line'>470314573174</td>
                    </tr>
                    <tr>
                        <td className='ContactsMain-table__line'>ОГРНИП:</td>
                        <td className='ContactsMain-table__line'>324237500000150</td>
                    </tr>
                    <tr>
                        <td className='ContactsMain-table__line'>Адрес Электронной почты:</td>
                        <td className='ContactsMain-table__line'><a className='ContactsMain-table__line_phonenumber' href="mailto:ig.golovko@yandex.ru">ig.golovko@yandex.ru</a></td>
                    </tr>
                    <tr>
                        <td className='ContactsMain-table__line'>Телефон:</td>
                        <td className='ContactsMain-table__line'><a className='ContactsMain-table__line_phonenumber' href="tel:+79283338384">+7(928)-333-83-84</a></td>
                    </tr>
                    <tr>
                        <td className='ContactsMain-table__line'>Телеграм-канал:</td>
                        <td className='ContactsMain-table__line'><a  href="https://t.me/urist_gelendzhik" className='ContactsMain-table__line_phonenumber'>https://t.me/urist_gelendzhik</a></td>
                    </tr>
                    <tr>
                        <td className='ContactsMain-table__line'>Адрес:</td>
                        <td className='ContactsMain-table__line'>город Геленджик, улица Островского, 146</td>
                    </tr>
                </table>

                <YMaps>
                    <div>
                        <p className='ContactsMain__namemap'>Найти нас на карте:</p>
                        <Map defaultState={{ center: [44.553472, 38.098555], zoom: 13 }} width={900} height={400}>
                            <Placemark geometry={[44.5537540167188,38.09887237775839]}/>
                        </Map>
                    </div>
                </YMaps>

                <div className='yandexstar'>
                    <iframe 
                        src="https://yandex.ru/sprav/widget/rating-badge/79004275306?type=rating" 
                        width="150"
                        height="50"
                        frameborder="0"
                    />             
                </div>
                <span className="ContactsMain__WhatsApp2"><NewWhatsApp/></span>
                <a href="mailto:ig.golovko@yandex.ru"><button className="ContactsMain__sendtomail"></button></a>
            </main>

            <div className='feedbackbox'>
                <form ref={form} onSubmit={sendEmail} className = "formContact">
                    <h3 id='top1' className='formContact-header'>Заявка на беспланую консультацию:</h3>
                    <p className='formContact-header__info'>После отправки формы, профессиональный юрист, свяжется с Вами в самое ближайшее время</p>

                    <input 
                        type="text" 
                        name="her_name" 
                        placeholder='Как к Вам можно обращаться?' 
                        className='formContact__input'
                        value = {nameresolt} 
                        onChange = { e => setNameresolt(e.target.value)}
                    />

                    <input 
                        type="email" 
                        name="from_name" 
                        placeholder='Ваш E-mail'
                        ref = {mail} 
                        className='formContact__input' 
                        value = {emailresolt} 
                        onChange = { e => setEmailresolt(e.target.value)}
                    />
                        <span ref = {emptyMail} className = 'formContact__error'>Поле не должно быть пустым.</span>
                        <span ref = {errorMail} className = 'formContact__error'>Email невалидный</span>

                    <div className='phoneCountry'>
                        <MuiTelInput 
                        sx={{width: '300px'}} 
                        splitCallingCode={true} 
                        defaultCountry="RU"
                        value={phone} 
                        onChange={handleChange} 
                        name='number_phone'
                        />   
                    </div>

                    <textarea name="message" placeholder='Опишите пожалуйста кратко Ваш вопрос' className='formContact__mesage'/>

                    <div className='check24-20'>
                        <FormGroup >
                            <FormControlLabel onInput={checkInput} control={<Checkbox defaultChecked />} label="Я предоставляю свое согласие на обработку моих персональных данных" 
                            sx={{ 
                            marginTop: 2,
                            '& .css-ahj2mt-MuiTypography-root': {fontSize: 15},
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                            }}/>
                        </FormGroup>
                    </div>

                    <div className='check19-10'>
                        <FormGroup >
                            <FormControlLabel onInput={checkInput} control={<Checkbox defaultChecked />} label="Я предоставляю свое согласие на обработку моих персональных данных" 
                            sx={{ 
                            marginTop: 2,
                            '& .css-ahj2mt-MuiTypography-root': {fontSize: 13.7},
                            '& .MuiSvgIcon-root': { fontSize: 25 }
                            }}/>
                        </FormGroup>
                    </div>
                    <span ref = {emptyCheck} className = 'formContact__error'>Поле не должно быть пустым.</span>

                    <div className='formContact__sendButton'>
                        <Stack direction="row" spacing={2}>     
                            <Button
                                disabled={emailresolt.length === 0} 
                                variant="contained"
                                type="submit" 
                                value="Send" 
                                sx={{ 
                                height: 45,
                                borderRadius: '10px',
                                backgroundColor: '#FF8000',
                                color: 'black',
                                '&:hover': { 
                                    backgroundColor: '#2EE59D',
                                    color: 'white',
                                    boxShadow: '0 15px 20px rgb(46, 229, 157, 0.4)',
                                }}}
                                endIcon={<SendIcon />}>Заказать консультацию
                            </Button>
                        </Stack>
                    </div>
                </form>

                <Feedback/>
                
            </div>
        </div>
    </>
    )
}

export default Contacts;