import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `3px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderTop: '3px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
  <>
    <div className='min20'>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular'}}>Договорная работа:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Проверка контрагентов (выявление неблагонадежных партнеров);</li>
            <li>Разработка и составление договоров, соглашений;</li>
            <li>Правовой анализ условий договора. Проверка соответствия договоров действующей нормативно-правовой базе. Поиск заранее невыгодных условий для клиента. Согласование разногласий;</li>
            <li>Разработка и заключение международных контрактов на английском, немецком, французском, китайском и др. языках;</li>
            <li>Составление дополнительных соглашений;</li>
            <li>Поиск дополнительных условий, необходимых для реализации сделки;</li>
            <li>Нивелирование возможных рисков и негативных последствий ненадлежащего исполнения договора;</li>
            <li>Прекращение договора через суд.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Семейное право:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
            <li>Разработка и составление брачного договора;</li>
            <li>Сопровождение бракоразводного процесса (консультирование по семейным спорам, подача иска на развод, представительство в суде, раздел и оценка имущества);</li>
            <li>Взыскание алиментов (подача искового заявления, получение исполнительного листа, сопровождение процесса взыскания);</li>
            <li>Споры о детях;</li>
            <li>Составление исков об определении порядка общения с детьми;</li>
            <li>Установление отцовства;</li>
            <li>Консультация юриста по наследственным спорам;</li>
            <li>Помощь юриста в восстановлении срока принятия наследства;</li>
            <li>Помощь юриста в определении долей в наследстве;</li>
            <li>Составление иска о признании завещания недействительным;</li>
            <li>Оспаривание наследства;</li>
            <li>Признание завещания недействительным;</li>
            <li>Помощь юриста при взыскании долгов наследодателя с наследников;</li>
            <li>Составление иска о признании наследника недостойным.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Претензионно-исковая работа, защита в суде:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: 17, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
            <li>Споры с банками и кредитными организациями (защита клиента от коллекторских агенств, представительство в службе судебных приставов);</li>
            <li>Проведение деловых переговоров, переписки и встреч, в целях урегулирования спора в досудебном порядке;</li>
            <li>Сбор доказательной базы;</li>
            <li>Оценка рисков до обращения в суд, составление мотивированного письменного заключения;</li>
            <li>Составление и направление обоснованной претензии (соблюдение предусмотренного законом порядка досудебного урегулирования спора);</li>
            <li>Урегулирование споров в претензионном порядке (заключение мировых соглашений, протоколирование переговоров);</li>
            <li>Подготовка и обоснование исковых требований;</li>
            <li>Составление искового заявления, заявления о выдаче судебного приказа, обращение в суд; </li>
            <li>Защита интересов клиента в судебных разбирательствах: участие в судебных заседаниях, подготовка процессуальных документов (мотивированных отзывов, возражений);</li>
            <li>Получение исполнительного документа в суде;</li>
            <li>Предъявление исполнительного документа в банк, службу судебных приставов;</li>
            <li>Контроль работы службы судебных приставов, подача процессуальных документов (жалоб, ходатайств);</li>
            <li>Участие в аресте, оценке и реализации имущества должника.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Миграционное законодательство:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Консультации юриста по вопросам миграционного законодательства РФ;</li>
            <li>Юридическая помощь иностранным гражданам;</li>
            <li>Консультирование по вопросам получения гражданства гражданами государств ближнего зарубежья;</li>
            <li>Консультирование по вопросам регистрации на территории РФ.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Жилищно-коммунальные споры:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Оспаривание и обжалование действий (бездействий) управляющих компаний, ТСЖ, ресурсоснабжающих организаций;</li>
            <li>Участие в судах и спорах по вопросам начисления платы за самовольное подключение (пользование) коммунальными ресурсами;</li>
            <li>Проверка законности начисления платы за коммунальные услуги;</li>
            <li>Консультирование по вопросам подключения (технологического присоединения) к сетям коммунальной инфроструктуры.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography sx={{fontSize: 25, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Сопровождение сделок с недвижимостью:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontSize: 18, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Юридический анализ условий сделки;</li>
            <li>Сопровождение сделки "под ключ";</li>
            <li>Консультирование по вопросам регистрации сделок с недвижимостью, оформление необходимых документов;</li>
            <li>Обжалование действий, бездействий регистрирующих органов;</li>
            <li>Консультирование по вопросам земельных правоотношений.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>

    <div className='max19'>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular'}}>Договорная работа:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Проверка контрагентов (выявление неблагонадежных партнеров);</li>
            <li>Разработка и составление договоров, соглашений;</li>
            <li>Правовой анализ условий договора. Проверка соответствия договоров действующей нормативно-правовой базе. Поиск заранее невыгодных условий для клиента. Согласование разногласий;</li>
            <li>Разработка и заключение международных контрактов на английском, немецком, французском, китайском и др. языках;</li>
            <li>Составление дополнительных соглашений;</li>
            <li>Поиск дополнительных условий, необходимых для реализации сделки;</li>
            <li>Нивелирование возможных рисков и негативных последствий ненадлежащего исполнения договора;</li>
            <li>Прекращение договора через суд.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Семейное право:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
            <li>Разработка и составление брачного договора;</li>
            <li>Сопровождение бракоразводного процесса (консультирование по семейным спорам, подача иска на развод, представительство в суде, раздел и оценка имущества);</li>
            <li>Взыскание алиментов (подача искового заявления, получение исполнительного листа, сопровождение процесса взыскания);</li>
            <li>Споры о детях;</li>
            <li>Составление исков об определении порядка общения с детьми;</li>
            <li>Установление отцовства;</li>
            <li>Консультация юриста по наследственным спорам;</li>
            <li>Помощь юриста в восстановлении срока принятия наследства;</li>
            <li>Помощь юриста в определении долей в наследстве;</li>
            <li>Составление иска о признании завещания недействительным;</li>
            <li>Оспаривание наследства;</li>
            <li>Признание завещания недействительным;</li>
            <li>Помощь юриста при взыскании долгов наследодателя с наследников;</li>
            <li>Составление иска о признании наследника недостойным.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }}>Претензионно-исковая работа, защита в суде:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{lineHeight: 1, fontSize: 15.5, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}}>
            <li>Споры с банками и кредитными организациями (защита клиента от коллекторских агенств, представительство в службе судебных приставов);</li>
            <li>Проведение деловых переговоров, переписки и встреч, в целях урегулирования спора в досудебном порядке;</li>
            <li>Сбор доказательной базы;</li>
            <li>Оценка рисков до обращения в суд, составление мотивированного письменного заключения;</li>
            <li>Составление и направление обоснованной претензии (соблюдение предусмотренного законом порядка досудебного урегулирования спора);</li>
            <li>Урегулирование споров в претензионном порядке (заключение мировых соглашений, протоколирование переговоров);</li>
            <li>Подготовка и обоснование исковых требований;</li>
            <li>Составление искового заявления, заявления о выдаче судебного приказа, обращение в суд; </li>
            <li>Защита интересов клиента в судебных разбирательствах: участие в судебных заседаниях, подготовка процессуальных документов (мотивированных отзывов, возражений);</li>
            <li>Получение исполнительного документа в суде;</li>
            <li>Предъявление исполнительного документа в банк, службу судебных приставов;</li>
            <li>Контроль работы службы судебных приставов, подача процессуальных документов (жалоб, ходатайств);</li>
            <li>Участие в аресте, оценке и реализации имущества должника.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Миграционное законодательство:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Консультации юриста по вопросам миграционного законодательства РФ;</li>
            <li>Юридическая помощь иностранным гражданам;</li>
            <li>Консультирование по вопросам получения гражданства гражданами государств ближнего зарубежья;</li>
            <li>Консультирование по вопросам регистрации на территории РФ.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Жилищно-коммунальные споры:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Оспаривание и обжалование действий (бездействий) управляющих компаний, ТСЖ, ресурсоснабжающих организаций;</li>
            <li>Участие в судах и спорах по вопросам начисления платы за самовольное подключение (пользование) коммунальными ресурсами;</li>
            <li>Проверка законности начисления платы за коммунальные услуги;</li>
            <li>Консультирование по вопросам подключения (технологического присоединения) к сетям коммунальной инфроструктуры.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography sx={{fontSize: 20, color: '#FF0000', fontWeight: 500, fontFamily: 'Noto Serif Regular' }} >Сопровождение сделок с недвижимостью:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{lineHeight: 1.1, fontSize: 16, color: '#0000FF', fontWeight: 400, fontFamily: 'Noto Serif Regular'}} >
            <li>Юридический анализ условий сделки;</li>
            <li>Сопровождение сделки "под ключ";</li>
            <li>Консультирование по вопросам регистрации сделок с недвижимостью, оформление необходимых документов;</li>
            <li>Обжалование действий, бездействий регистрирующих органов;</li>
            <li>Консультирование по вопросам земельных правоотношений.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  </>
  );
}