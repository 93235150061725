import './styles/allStyles.scss';
import React from 'react';

function NewWhatsApp() {
    return (
        <>
            <div className='whatsappWeb'>
                <a href="https://web.whatsapp.com/send?phone=79283338384" target="_blank" rel="noopener noreferrer" >
                    <i className="fa fa-whatsapp"></i>
                    <div type="button" className="whatsapp-button">
                        <div className="text-button">
                            <i className="fa fa-whatsapp"></i><span>WhatsApp</span>
                        </div>
                    </div>
                </a>        
            </div>

            <div className='whatsappMobile'>
                <a href="https://api.whatsapp.com/send?phone=79283338384" target="_blank" rel="noopener noreferrer" >
                    <i className="fa fa-whatsapp"></i>
                    <div type="button" className="whatsapp-button">
                        <div className="text-button">
                            <i className="fa fa-whatsapp"></i><span>WhatsApp</span>
                        </div>
                    </div>
                </a>        
            </div>
        </>
    )
}
export default NewWhatsApp;